<template>
  <div class="EmptyList">
    <div class="EmptyIconWrap">
      <img :src="getEmptyIcon()" />
    </div>
    <span class="EmptyText">{{ mixWB('NO_SCREENINGS_TO_SHOW') }}</span>

    <Button
      class="EmptyButton"
      v-if="currentUserRestrictions.canStartScreenings"
      :text="mixWB('START_NEW_SCREENING')"
      :path="{ name: 'ScreeningsNew' }" />
  </div>
</template>

<script>
import * as EmptyIcons from '@/assets/svg/empty-icons'
import { Button } from '@/components/Buttons'
import { mapGetters } from 'vuex'

export default {
  name: 'EmptyList',
  data() {
    return {
      randomValue: Math.floor(Math.random() * 5) + 1,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRestrictions',
    ]),
  },
  methods: {
    getEmptyIcon() {
      return EmptyIcons[`empty${ this.randomValue }`]
    },
  },
  components: {
    Button,
  },
}
</script>

<style lang="stylus" scoped>
.EmptyList
  position relative
  display flex
  flex-direction column
  align-items center

.EmptyIconWrap
  margin-top 50px
  box(150px)
  margin-bottom 20px
  img
    box(100%)
    opacity 0.25

.EmptyText
  color $color_grey_light
  font-size 1.25rem
  max-width 60%
  text-align center
  margin-bottom 20px

.EmptyButton
  min-width 75%

</style>
