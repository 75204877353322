<template>
  <div
    class="ScreeningItem"
    :class="[item.getStatus().class, {'NotSupported': !isSupportedScreeningVersion}]">
    <div class="Top">
      <span
        class="Version"
        v-if="!isSupportedScreeningVersion">
        {{ mixWB('VERSION') }} {{ item.appVersion.split('.')[0] }}</span>
      <div class="Info">
        <span class="Title">{{ item.address.address }}</span>
        <span>{{ item.address.postalCode }} {{ item.address.city }}</span>
        <span v-if="caseAndSampleNumbers">{{ caseAndSampleNumbers }}</span>
        <span v-if="item.enterpriseID">
          {{ mixWB('ENTERPRISE_ID') }}: {{ item.enterpriseID }}</span>
        <span v-if="screener">{{ screener.fullName }}</span>
        <div class="StatusWrap">
          <span class="Status">{{ item.getStatus().text }}</span>
          <span
            class="Archived"
            v-if="statusType === 'archived'">{{ mixWB('ARCHIVED') }}</span>
        </div>
        <div class="Space"/>
        <span v-if="item.builderFullName">{{ item.builderFullName }}</span>
        <span v-if="getNoOfUnitsText()">{{ getNoOfUnitsText() }}</span>
        <span>{{ mixWB('CREATED') }}: {{ mixGetDate(item.createdAt) }}</span>
        <span>{{ mixWB('LAST_UPDATED') }}: {{ mixGetDate(item.lastUpdated) }}</span>
        <span v-if="item.getScreeningTimeUsed()">
          {{ mixWB('TIME_USED') }}: {{ item.getScreeningTimeUsed() }}
        </span>

      </div>
      <div
        v-if="item && item.frontImage"
        class="ImageWrap">
        <MultiImage :image="item.frontImage" />
      </div>
      <div class="DotMenuWrap">
        <DotMenu
          :use-component="menuToUse"
          :dataToComponent="{
            canBeEditedByCurrentUser,
            canBeEdited: getCanBeEditedStatus()
          }"
          position="normal"
          @menu-click="onMenuClick" />
      </div>
    </div>
    <div class="Bottom">
      <Button
        :text="mixWB('EDIT_SCREENING')"
        :path="{ name: 'ScreeningsUpdate', params: { screeningID: item.id } }"
        :isDisabled="!getCanBeEditedStatus() || !isSupportedScreeningVersion"
        v-if="statusType === 'ongoing'" />
      <Button
        v-else
        :isDisabled="!isSupportedScreeningVersion"
        :text="mixWB('SEE_RESULT')"
        :path="{ name: 'ResultOverview', params: { screeningID: item.id } }" />
    </div>
  </div>
</template>

<script>
import MultiImage from '@/components/Images/MultiImage'
import DotMenu from '@/components/DotMenu/DotMenu.vue'
import OngoingScreeningMenu from '@/components/ScreeningItems/Menus/OngoingScreeningMenu.vue'
import CompletedScreeningMenu from '@/components/ScreeningItems/Menus/CompletedScreeningMenu.vue'
import ArchivedScreeningMenu from '@/components/ScreeningItems/Menus/ArchivedScreeningMenu.vue'
import Button from '@/components/Buttons/Button.vue'
import { mapGetters } from 'vuex'
import Screening from '@/globals/javascript/models/Screening'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      OngoingScreeningMenu,
      CompletedScreeningMenu,
      ArchivedScreeningMenu,
    }
  },
  computed: {
    ...mapGetters([
      'allUsers',
      'currentUser',
      'currentAccount',
      'selectedUnits',
    ]),
    isSupportedScreeningVersion() {
      return this.item.appVersion.indexOf('1') === 0
    },
    statusType() {
      if (this.item.isArchived) {
        return 'archived'
      }
      if (this.item.isCompleted) {
        return 'completed'
      }
      return 'ongoing'
    },
    screener() {
      return this.allUsers.find((x) => x.id === this.item.userID)
    },
    canBeEditedByCurrentUser() {
      if (this.currentUser.restrictions.canStartScreenings) {
        return true
      }
      return false
    },
    menuToUse() {
      if (this.statusType !== 'ongoing' && !this.canBeEditedByCurrentUser) {
        return false
      }
      if (this.statusType === 'ongoing') {
        return this.OngoingScreeningMenu
      }
      if (this.statusType === 'completed') {
        return this.CompletedScreeningMenu
      }
      return this.ArchivedScreeningMenu
    },
    caseNumber() {
      if (!this.item.screeningNumber) {
        return false
      }

      return `${
        this.currentAccount.companyInitials
      }-${
        this.item.screeningYear || 2020
      }-${
        this.item.screeningNumber
      }`
    },
    personalSamplingID() {
      if (!this.item.samplingID) {
        return false
      }

      return `${
        this.currentAccount.companyInitials
      }-${
        this.screener.initials
      }-${
        this.item.samplingID
      }`
    },
    caseAndSampleNumbers() {
      let text = ''

      if (this.caseNumber) {
        text += this.caseNumber
      }

      if (this.personalSamplingID) {
        text += `${ text ? ' // ' : '' }${ this.personalSamplingID }`
      }

      return text
    },
  },
  methods: {
    getNoOfUnitsText() {
      if (this.item.selectedUnits && this.item.selectedUnits.count) {
        let text = ''
        text += `${
          this.item.selectedUnits.count === 1
            ? this.mixWB('1_UNIT')
            : this.mixWB('X_UNITS', [this.item.selectedUnits.count])
        }`

        // Add building names for enterprise projects
        if (this.item.enterpriseID) {
          if (this.item.getBuildingNames().length) {
            text += ` (${ this.item.getBuildingNames().join(', ') })`
          }
        }
        return text
      }
      if (this.item.selectedBuildings && this.item.selectedBuildings.count) {
        return `${
          this.item.selectedBuildings.count === 1
            ? this.mixWB('1_BUILDING')
            : this.mixWB('X_BUILDINGS', [this.item.selectedBuildings.count])
        }`
      }
      return ''
    },
    getCanBeEditedStatus() {
      return this.mixCompareVersionsSameOrNewer({
        versionA: this.item.appVersion,
        versionB: '1.19.0',
      })
    },
    onMenuClick(value) {
      if (value === 'edit') {
        this.editScreening()
      }
      if (value === 'show-result') {
        this.showResult()
      }
      if (value === 'show-in-backend') {
        this.showInBackend()
      }
      if (value === 'archive') {
        this.archiveScreening()
      }
      if (value === 'make-active') {
        this.makeScreeningActive()
      }
    },
    editScreening() {
      this.$router.push({ name: 'ScreeningsUpdate', params: { screeningID: this.item.id } })
    },
    showResult() {
      this.$router.push({ name: 'ResultOverview', params: { screeningID: this.item.id } })
    },
    showInBackend() {
      Screening.showInBackend(this.item.id)
    },
    archiveScreening() {
      const answer = window.confirm(this.mixWB('ARE_YOU_SURE'))
      if (!answer) {
        return
      }

      this.$store.dispatch('updateScreening', {
        screening: this.item,
        setAsArchived: true,
      })
    },
    makeScreeningActive() {
      this.$store.dispatch('updateScreening', {
        screening: this.item,
        setAsActive: true,
      })
    },
  },
  components: {
    DotMenu,
    Button,
    MultiImage,
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningItem
    position relative
    display flex
    flex-direction column
    justify-content space-between
    background-color $color_grey_lightest
    height 100%
    box-shadow $box_shadow_1
    .Top
      display flex
      align-items center
      justify-content space-between
      padding 15px 0px 10px 15px
      border 1px solid lighten($color_grey_lighter, 25%)
      border-bottom none
      height 100%
    .Bottom
      >>> .ButtonWrap
        margin-bottom 0

  .Version
    position absolute
    top 15px
    right 15px
    padding 2px 3px
    display inline-block
    line-height 1
    margin-bottom 0
    color #fff
    background-color $color_salmon_light

  .Version
    position absolute
    top 15px
    right 15px
    padding 2px 3px
    line-height 1
    color #fff
    background-color $color_salmon_light

  .NotSupported
    opacity 0.5
    pointer-events none

  .Info
    flex-grow 2
    margin-right 10px
    font-size 0.75rem
    height 100%
    span
      display block
      margin-bottom 2.5px
    .Title
      max-width 100%
      font-size 1rem
      font-weight bold
      text-transform uppercase
      truncated()
    .StatusWrap
      span
        padding 2px 3px
        display inline-block
        line-height 1
        margin-bottom 0
        color #fff
      .FlowClass1 &
        .Status
          background-color $color_salmon_light
      .FlowClass2 &
        .Status
          background-color $color_purple_light
      .FlowClass3 &
        .Status
          background-color $color_primary_light
      .Archived
        margin-left 2.5px
        background-color $color_grey_light
        font-style italic
        padding-right 5px
    .Space
      margin-bottom 10px

  .ImageWrap
    box(95px)
    flex-shrink 0
    background-color $color_grey_lightest

  .DotMenuWrap
    flex-center-children()
    flex-shrink 0

</style>
