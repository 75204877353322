<template>
  <div class="ArchivedScreeningMenu">
    <DotMenuItem
      v-if="currentUser.isAdmin"
      :text="mixWB('SHOW_IN_BACKEND')"
      itemID="show-in-backend"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      :text="mixWB('MAKE_ACTIVE_AGAIN')"
      itemID="make-active"
      @dot-item-click="onDotItemClick" />
  </div>
</template>

<script>
import DotMenuItem from '@/components/DotMenu/DotMenuItem.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ArchivedScreeningMenu',
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
  },
  methods: {
    onDotItemClick(itemID) {
      this.$emit('pass-to-parent', { name: 'menu-click', value: itemID })
      this.$emit('close-menu')
    },
  },
  components: {
    DotMenuItem,
  },
}
</script>

<style lang="stylus" scoped>
  .ArchivedScreeningMenu
    display block
</style>
