<template>
  <div class="OngoingScreeningMenu">
    <DotMenuItem
      v-if="canBeEditedByCurrentUser && canBeEdited"
      :text="mixWB('EDIT_SCREENING')"
      itemID="edit"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      :text="mixWB('SEE_RESULT_PREVIEW')"
      itemID="show-result"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      v-if="currentUser.isAdmin"
      :text="mixWB('SHOW_IN_BACKEND')"
      itemID="show-in-backend"
      @dot-item-click="onDotItemClick" />
    <DotMenuItem
      v-if="canBeEditedByCurrentUser"
      :text="mixWB('ARCHIVE')"
      itemID="archive"
      @dot-item-click="onDotItemClick"
      color="red" />
  </div>
</template>

<script>
import DotMenuItem from '@/components/DotMenu/DotMenuItem.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OngoingScreeningMenu',
  props: {
    canBeEditedByCurrentUser: {
      type: Boolean,
      required: true,
    },
    canBeEdited: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'currentUser',
    ]),
  },
  methods: {
    onDotItemClick(itemID) {
      this.$emit('pass-to-parent', { name: 'menu-click', value: itemID })
      this.$emit('close-menu')
    },
  },
  components: {
    DotMenuItem,
  },
}
</script>

<style lang="stylus" scoped>
</style>
